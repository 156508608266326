/** @jsx jsx */
import PropTypes from 'prop-types'
import { Card, jsx } from 'theme-ui'
import CartSummary from '~/components/Cart/Summary'
import { utils } from '@chordcommerce/gatsby-theme-autonomy'

const OrderConfirmationSummary = ({ cart = {} }) => {
  const {
    displayTotal,
    displayItemTotal,
    displayTaxTotal,
    displayShipTotal,
    displayTotalApplicableStoreCredit,
    displayStoreCreditRemainingAfterCapture,
    displayOrderTotalAfterStoreCredit,
  } = cart

  const { getAllCartPromotionsForDisplay } = utils
  const promotions = getAllCartPromotionsForDisplay(cart)

  return (
    <Card
      sx={{
        width: '100%',
      }}
    >
      <CartSummary
        displayTotalApplicableStoreCredit={displayTotalApplicableStoreCredit}
        displayStoreCreditRemainingAfterCapture={
          displayStoreCreditRemainingAfterCapture
        }
        displayOrderTotalAfterStoreCredit={displayOrderTotalAfterStoreCredit}
        displayItemTotal={displayItemTotal}
        displayTaxTotal={displayTaxTotal}
        displayShipTotal={displayShipTotal}
        displayTotal={displayTotal}
        promotions={promotions}
        orderConfirmed={true}
      />
    </Card>
  )
}

OrderConfirmationSummary.propTypes = {
  cart: PropTypes.shape({
    displayTotal: PropTypes.string,
    displayItemTotal: PropTypes.string,
    displayTaxTotal: PropTypes.string,
    displayShipTotal: PropTypes.string,
  }),
}

export default OrderConfirmationSummary
