import PropTypes from 'prop-types'
import React from 'react'
import { Flex, Box } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import CheckoutButton from '../CheckoutButton/index.js'
import RowWrapper from './RowWrapper.jsx'
import Text from '~/components/IntlComponents/Text/index.jsx'

const CartSummary = ({
  displayTotalApplicableStoreCredit = '',
  displayStoreCreditRemainingAfterCapture = '',
  displayOrderTotalAfterStoreCredit = '',
  displayItemTotal,
  displayTaxTotal,
  displayShipTotal,
  displayTotal,
  promotions,
  orderConfirmed = false,
  eligibleForFreeShipping = false,
}) => {
  const translate = useTranslate()

  // for regex group "Promotions ()"
  const regEx = /(?:\()([^*]*)(?=\)$)/g

  const creditApplied = displayTotalApplicableStoreCredit !== '$0.00'

  const creditAvailableAfterCapture =
    displayStoreCreditRemainingAfterCapture !== '$0.00'

  return (
    <Flex
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {/* {!orderConfirmed && <PromoCode promotion={promoCode} />} */}
      <Box
        sx={{
          borderTop: '3px solid var(--theme-ui-colors-secondary)',
          borderBottom: '3px solid var(--theme-ui-colors-secondary)',
          fontFamily: 'Marby',
          padding: '1em 0',
        }}
      >
        {displayItemTotal && (
          <RowWrapper>
            <Text>{`${translate('cart.subtotal')}:`}</Text>
            <Text>{displayItemTotal}</Text>
          </RowWrapper>
        )}

        {promotions.map(({ id, code, label, amount }) => {
          return (
            <RowWrapper key={id}>
              <Text sx={{ textTransform: 'capitalize' }}>
                {code
                  ? code + ' - ' + (regEx.exec(label)?.[1] || label)
                  : regEx.exec(label)?.[1] || label}
              </Text>
              <Text sx={{ color: '#03A009' }}>{`-$${(amount * -1).toFixed(
                2,
              )}`}</Text>
            </RowWrapper>
          )
        })}
        {displayTaxTotal && (
          <RowWrapper>
            <Text>
              {orderConfirmed
                ? translate('cart.tax')
                : translate('cart.tax') +
                  translate('cart.calculated_at_checkout')}
            </Text>
            <Text>{displayTaxTotal}</Text>
          </RowWrapper>
        )}

        {displayShipTotal && (
          <RowWrapper>
            <Text>
              {orderConfirmed || eligibleForFreeShipping
                ? `${translate('cart.shipping')}:`
                : translate('cart.shipping') +
                  translate('cart.calculated_at_checkout')}
            </Text>
            <Text>
              {eligibleForFreeShipping
                ? translate('cart.free_shipping')
                : displayShipTotal}
            </Text>
          </RowWrapper>
        )}
        {creditApplied && (
          <RowWrapper>
            <Text>{`${translate('cart.store_credit')}:`}</Text>
            <Text>{displayTotalApplicableStoreCredit}</Text>
          </RowWrapper>
        )}
      </Box>
      {orderConfirmed ? (
        <RowWrapper>
          {/* {!orderConfirmed && <Text sx={{textTransform:'capitalize', variant: 'text.h5'}}>{translate('cart.estimated_total')}</Text>}
          {orderConfirmed && <Text sx={{textTransform:'capitalize', variant: 'text.h5'}}>{translate('cart.total')}</Text>} */}
          <Text sx={{ textTransform: 'capitalize', variant: 'text.h5' }}>
            {translate('cart.total')}
          </Text>
          <Text sx={{ variant: 'text.h5' }}>
            {displayOrderTotalAfterStoreCredit}
          </Text>
        </RowWrapper>
      ) : (
        <RowWrapper>
          {/* {!orderConfirmed && <Text sx={{textTransform:'capitalize', variant: 'text.h5'}}>{translate('cart.estimated_total')}</Text>}
        {orderConfirmed && <Text sx={{textTransform:'capitalize', variant: 'text.h5'}}>{translate('cart.total')}</Text>} */}
          <Text sx={{ textTransform: 'capitalize', variant: 'text.h5' }}>
            {translate('cart.estimated_total')}
          </Text>
          <Text sx={{ variant: 'text.h5' }}>
            {displayOrderTotalAfterStoreCredit}
          </Text>
        </RowWrapper>
      )}

      {!orderConfirmed && <CheckoutButton />}
      {!orderConfirmed && creditApplied && (
        <RowWrapper>
          <Text sx={{ marginTop: '0.5rem' }}>
            <strong>{translate('cart.important_sec_one')} </strong>
            {translate('cart.important_sec_two')}
            <strong> {displayTotal} </strong>
            {translate('cart.important_sec_three')}
            <strong>{translate('cart.important_sec_four')} </strong>
            {translate('cart.important_sec_five')}
            <strong> {displayTotalApplicableStoreCredit.slice(1)}</strong>
            {translate('cart.important_sec_six')}
            {creditAvailableAfterCapture ? (
              <>
                {translate('cart.important_sec_eight')}
                <strong> {creditAvailableAfterCapture} </strong>
                {translate('cart.important_sec_nine')}
              </>
            ) : (
              <strong>{translate('cart.important_sec_seven')} </strong>
            )}
          </Text>
        </RowWrapper>
      )}
    </Flex>
  )
}

CartSummary.propTypes = {
  displayItemTotal: PropTypes.string,
  displayTaxTotal: PropTypes.string,
  displayShipTotal: PropTypes.string,
  displayTotal: PropTypes.string,
  promotions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      displayAmount: PropTypes.string,
    }),
  ),
  orderConfirmed: PropTypes.bool,
}

export default CartSummary
