/** @jsx jsx */
import {
  useAnalytics,
  useProduct,
  useTranslate,
} from '@chordcommerce/gatsby-theme-autonomy'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { Box, Flex, jsx } from 'theme-ui'
import Text from '~/components/IntlComponents/Text'
import QuantitySelector from '~/components/Cart/QuantitySelector'
import SubscriptionRemoveButton from '~/components/Cart/Subscription/RemoveButton'
import useDonationImgQuery from '~/hooks/graphql/queries/use-donation-image'
import Subscribe from '~/assets/images/icons/subscribe.svg'
import usePromoProductImage from '~/hooks/graphql/queries/use-promo-product-image'
import LineItemPrice from '~/components/Cart/LineItemPrice'
import useGetProductOrKitByLocale from '~/utils/intl/context/hooks/use-get-product-or-kit-by-locale'
import { useVariantMainImage } from '~/hooks/components/use-product-main-image'
import useBundle from '~/hooks/graphql/queries/use-bundle'

const LineItem = ({
  id,
  sku,
  slug,
  name,
  options,
  quantity,
  displayAmount,
  price,
  total,
  adjustments,
  variant,
  orderConfirmed = false,
  subscriptions = [],
  item,
}) => {
  const translate = useTranslate()
  const { trackProductClicked } = useAnalytics()
  const product = useProduct(slug)
  const path = sku.includes('|') ? 'bundles' : 'products'
  const url = `/${path}/${slug}/`

  const { image } = useVariantMainImage({ sku, slug })

  const productOrKitByLocale = useGetProductOrKitByLocale({ slug })

  const bundle = useBundle(slug)

  const bundleImage = bundle?.mainImage

  const imageSpecial = usePromoProductImage(sku, slug)

  let donationImage = useDonationImgQuery() //Donation Products are not available via AllProducts Selector, this ensures a donation image is found via static query

  let priceProps = {
    variant,
    displayAmount,
    price,
    total,
    adjustments,
    quantity,
    orderConfirmed,
  }

  return (
    <Box>
      <Flex
        sx={{
          flexDirection: ['column', 'row'],
          justifyContent: 'space-between',
        }}
      >
        <Flex
          sx={{
            flexDirection: ['row'],
          }}
        >
          <Box
            sx={{
              minWidth: ['96px', '144px'],
              maxWidth: '150px',
            }}
          >
            <Link to={url} onClick={() => trackProductClicked({ product })}>
              {image ? (
                <GatsbyImage
                  sx={{
                    border: '3px solid var(--theme-ui-colors-tertiary)',
                    borderRadius: '50%',
                  }}
                  image={image.gatsbyImageData}
                  alt={image.title}
                />
              ) : bundleImage ? (
                <GatsbyImage
                  sx={{
                    border: '3px solid var(--theme-ui-colors-tertiary)',
                    borderRadius: '50%',
                    aspectRatio: '1',
                  }}
                  image={bundleImage.gatsbyImageData}
                  alt={bundleImage.title}
                />
              ) : imageSpecial ? (
                <GatsbyImage
                  sx={{
                    border: '3px solid var(--theme-ui-colors-tertiary)',
                    borderRadius: '50%',
                  }}
                  image={imageSpecial.gatsbyImageData}
                  alt={imageSpecial.title}
                />
              ) : (
                <GatsbyImage
                  sx={{
                    border: '3px solid var(--theme-ui-colors-tertiary)',
                    borderRadius: '50%',
                  }}
                  image={
                    donationImage.allContentfulVariant.edges[0].node.product[0]
                      .mainImage.gatsbyImageData
                  }
                  alt={'World Central Kitchen Donation Image'}
                />
              )}
            </Link>
          </Box>
          <Flex
            sx={{
              flexDirection: 'column',
              flexGrow: 1,
              height: '100%',
              justifyContent: 'center',
              padding: '1.5em',
            }}
          >
            <Text
              sx={{
                marginBottom: '0.5rem',
                variant: 'text.h5',
                fontFamily: 'marby',
                fontSize: '18px',
              }}
            >
              {productOrKitByLocale?.name ?? name}
              {/* TODO: tooltip component */}
              {subscriptions.length > 0 && (
                <div
                  data-tooltip="Pause, Skip, or Cancel anytime!"
                  sx={{
                    display: 'inline-block',
                    cursor: 'pointer',
                    '&[data-tooltip]': { position: 'relative' },
                    '&[data-tooltip]:after': {
                      content: 'attr(data-tooltip)',
                      position: 'absolute',
                      backgroundColor: 'primary',
                      borderRadius: '8px',
                      border: '2px solid',
                      borderColor: 'secondary',
                      m: '2px',
                      width: '150px',
                      p: '10px',
                      fontSize: '14px',
                      pointerEvents: 'none',
                      textAlign: 'center',
                      opacity: 0,
                      display: 'block',
                      transition: '.2s ease',
                    },
                    '&[data-tooltip]:hover::after': { opacity: 1 },
                  }}
                >
                  <Subscribe
                    sx={{
                      ml: '8px',
                      stroke: 'secondary',
                    }}
                  />
                </div>
              )}
            </Text>
            <Text
              sx={{
                fontFamily: 'Marby',
                marginBottom: '0.5rem',
                variant: 'text.p',
                textTransform: 'uppercase',
              }}
            >
              {options}
            </Text>
            <LineItemPrice orderConfirmed {...priceProps} />
            <Text variant="body" sx={{ fontFamily: 'Marby' }}>
              {translate('cart.quantity', { quantity })}
            </Text>

            {subscriptions.length > 0 && (
              <Text variant="body">
                {translate('subscriptions.subscription_lineitem_renew', {
                  unit:
                    subscriptions[0].intervalLength === 1
                      ? subscriptions[0].intervalUnits
                      : subscriptions[0].interval,
                })}
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex
          sx={{
            flexDirection: ['row', 'column'],
            alignItems: ['center', 'flex-end'],
            justifyContent: 'space-between',
            height: ['48px', '160px'],
          }}
        >
          {!orderConfirmed && subscriptions.length === 0 && (
            <QuantitySelector
              quantity={quantity}
              lineItemId={id}
              max={5}
              sx={{
                marginRight: ['0', null, '4px'],
                width: ['96px', '128px'],
              }}
            />
          )}

          {!orderConfirmed && subscriptions && subscriptions.length > 0 && (
            <SubscriptionRemoveButton lineItemId={id} />
          )}
        </Flex>
      </Flex>
      {!!item.giftCards.length && (
        <Flex sx={{ flexDirection: 'column', px: '1.5rem', pb: '1rem' }}>
          <Text>
            {translate('gift_card.recipient', {
              email: item.giftCards[0].recipientEmail,
            })}
          </Text>
          {!!item.giftCards[0].giftMessage && (
            <Text>
              {translate('gift_card.message', {
                message: item.giftCards[0].giftMessage,
              })}
            </Text>
          )}
        </Flex>
      )}
    </Box>
  )
}

LineItem.propTypes = {
  id: PropTypes.number.isRequired,
  sku: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string,
  quantity: PropTypes.number,
  displayAmount: PropTypes.string,
  singleDisplayAmount: PropTypes.string,
  orderConfirmed: PropTypes.bool,
}

export default LineItem
