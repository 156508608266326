/** @jsx jsx */
import { useState } from 'react'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { Button, jsx } from 'theme-ui'
import { loadStripe } from '@stripe/stripe-js'
import Text from '~/components/IntlComponents/Text'
import Spinner from '~/components/Generic/Spinner'
import { useTikTokHelper } from '~/components/TikTokHelper'
import useLocaleCartLogic from '~/utils/intl/context/hooks/use-locale-cart-logic'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK_KEY, {
  stripeAccount: process.env.GATSBY_STRIPE_CONNECTED_ACCOUNT,
})

const CheckoutButton = () => {
  const { checkoutWithLocaleRestrictions: checkout } = useLocaleCartLogic()
  const { initiateCheckout } = useTikTokHelper()
  const translate = useTranslate()
  const [buttonIsLoading, setButtonIsLoading] = useState(false)
  const [apiError, setApiError] = useState(null)

  const handleSubmit = async event => {
    if (event) event.preventDefault()

    setButtonIsLoading(true)
    setApiError(null)
    try {
      const cart = await checkout()
      await initiateCheckout()
      const stripe = await stripePromise
      stripe.redirectToCheckout({
        sessionId: cart.checkoutSessionId,
      })
    } catch (error) {
      setApiError(translate('error.api.default'))
      console.log(error, { source: 'CheckoutButton' })
    }

    setButtonIsLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Button
        sx={{
          marginTop: '1rem',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {buttonIsLoading && (
          <Spinner data-testid="spinner" size="15" color="inherit" />
        )}

        {!buttonIsLoading && (
          <Text variant="link">{translate('cart.checkout')}</Text>
        )}
      </Button>

      {apiError && (
        <Text color="errorDark" variant="textLink" mt="1.5rem">
          {apiError}
        </Text>
      )}
    </form>
  )
}

export default CheckoutButton
