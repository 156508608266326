/** @jsx jsx */
import { utils } from '@chordcommerce/gatsby-theme-autonomy'
import { jsx } from 'theme-ui'
import React, { memo, useState } from 'react'
import useRefCallback from '~/hooks/utils/use-ref-callback'
import './fairing.css'

/**
 * @param {{cart: {any}}} param0 Takes the Chord Cart data returned from the
 * finalizeCheckout() function. Requires `GATSBY_FAIRING_API_KEY` to be set
 * in the environment variables
 * See more info here @link https://docs.fairing.co/docs/sdk
 * @returns
 */
const FairingSurvey = ({ cart }) => {
  const [isOpen, setIsOpen] = useState(true)
  const { getAllCartPromotionsForDisplay } = utils
  const promotions = getAllCartPromotionsForDisplay(cart)
  const coupon = promotions.find(promo => promo.code && promo.amount < 0)

  const [ref] = useRefCallback({
    callback: async node => {
      const API_KEY = process.env.GATSBY_FAIRING_API_KEY
      //   console.log(!node || !window.Fairing || !cart)
      if (!node || !window.Fairing || !cart) return

      const customer = {
        email: cart.email,
        id: cart.userId,
        order_count: 1, // Used to determine if a customer is new or not. If this
        // value is 1, the customer is considered new, if it is
        // greater than one, the customer is considered returning. NOTE: We don't have this.
      }

      const transaction = {
        country_code: cart.locale.split('-')?.[1], // REQUIRED The shipping address country code
        coupon_amount: coupon && -1 * coupon.amount, // If a discount was applied, the amount of the discount. NOTE: multiply by -1 to make positive
        coupon_code: coupon?.code, // If a discount was applied, the discount code
        created_at: cart.updatedAt, // REQUIRED The UTC timestamp of when the order was created. NOTE: Using updatedAt
        currency_code: cart.currency, // REQUIRED The ISO currency code of the transaction currency
        id: cart.id, // REQUIRED Your internal order ID
        landing_page_path: '/order', // The landing page for this transaction. NOTE: shows in order/confirmation so I think that's what's supposed to be there.
        locality: cart.shipAddress?.city, // REQUIRED The shipping address city
        number: cart.number, // REQUIRED The transaction number. Useful if your order ID is
        // is different from your customer-facing order number
        platform: 'chord', // REQUIRED The name of the ecommerce platform where the order
        // information is stored. For example "magento" or
        // "bigcommerce". Any value is valid. It is provided in
        // the data export to aid in analysis. NOTE: I guess this should be Chord?
        postcode: cart.shipAddress?.zipcode, // REQUIRED The shipping address post code
        // referring_url: 'https://google.com', // The referring site for the transaction. NOTE: Not sure we can get this.
        region: cart.shipAddress?.stateText, // REQUIRED The shipping address state or province
        source: 'website', // REQUIRED The source of the transaction, e.g. "website"
        total: cart.total, // REQUIRED The order total in the local currency
        total_usd: cart.total, // REQUIRED The order total in USD
        // utm_campaign: 'summer fun', // UTM campaign NOTE: Not sure we can get this without saving in session storage.
        // utm_content: 'splashing waves', // UTM content
        // utm_medium: 'digital', // UTM medium
        // utm_source: 'paid search', // UTM source
        // utm_term: 'summer wine', // UTM term
      }

      try {
        const fairing = window.Fairing(API_KEY, node, {
          transaction: transaction,
          customer: customer,
          config: {
            testMode: true,
            integrations: {
              // Enable / Disable integrations for GA, GTM, RockerBox
              // https://docs.fairing.co/docs/sdk
            },
          },
        })

        await fairing.nextQuestion()

        process.NODE_ENV !== 'production' && window.Fairing.toggleDebug()
      } catch (e) {
        console.log('Error loading Fairing Survey:', e)
      }
    },
  })

  return (
    <div
      ref={ref}
      sx={{
        mx: 'auto',
        font: 'heading',
        background: 'transparent',
        borderRadius: '16px',
        padding: '16px',
        my: '16px',
        height: 'fit-content',
        textAlign: 'left',
      }}
      id="question_stream_target"
    ></div>
  )
}

export default memo(FairingSurvey)
