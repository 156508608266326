import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'theme-ui'
import Text from '~/components/IntlComponents/Text'
import Spinner from '~/components/Generic/Spinner'
import useLocaleCartLogic from '~/utils/intl/context/hooks/use-locale-cart-logic'

const SubscriptionRemoveButton = ({ lineItemId }) => {
  const translate = useTranslate()

  const { removeFromCart } = useLocaleCartLogic()
  const [buttonIsLoading, setButtonIsLoading] = useState(false)
  const [apiError, setApiError] = useState(null)

  useEffect(() => setButtonIsLoading(false), [lineItemId])

  const handleSubmit = async event => {
    if (event) event.preventDefault()

    setButtonIsLoading(true)
    setApiError(null)

    try {
      await removeFromCart({ lineItemId })
      document.dispatchEvent(new CustomEvent('dk_cart_updated'))
    } catch (error) {
      setApiError(translate('error.api.default'))
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Button
        type="submit"
        sx={{
          flexShrink: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {translate('subscriptions.remove_from_cart')}
        {buttonIsLoading && <Spinner size="15" color="inherit" />}
      </Button>
      {apiError && (
        <Text color="errorDark" variant="textLink" mt="1.5rem">
          {apiError.toString()}
        </Text>
      )}
    </form>
  )
}

SubscriptionRemoveButton.propTypes = {
  lineItemId: PropTypes.number.isRequired,
}

export default SubscriptionRemoveButton
