import { graphql, useStaticQuery } from 'gatsby'

const useDonationImgQuery = () => {
  const data = useStaticQuery(
    graphql`
      query MyQuery {
        allContentfulVariant(filter: { sku: { eq: "donation1" } }) {
          edges {
            node {
              sku
              product {
                mainImage {
                  gatsbyImageData(width: 500, quality: 80)
                }
              }
            }
          }
        }
      }
    `,
  )

  return data
}

export default useDonationImgQuery
