import { useStaticQuery, graphql } from 'gatsby'
import { getDataByLocale } from '~/hooks/utils/get-data-by-locale'
const useConfirmationAssets = locale => {
  const data = useStaticQuery(graphql`
    query OrderConfirmations {
      # AMERICA
      confirmationAssets_enUS: contentfulSiteContent(
        node_locale: { eq: "en-US" }
        slug: { eq: "order-confirmation" }
      ) {
        donationLegalCopy: longText {
          childMarkdownRemark {
            html
          }
        }
        delayedShippingNote: shortText
      }

      # CANADA - ENG
      confirmationAssets_enCA: contentfulSiteContent(
        node_locale: { eq: "en-CA" }
        slug: { eq: "order-confirmation" }
      ) {
        donationLegalCopy: longText {
          childMarkdownRemark {
            html
          }
        }
        delayedShippingNote: shortText
      }

      # CANADA - FR
      confirmationAssets_frCA: contentfulSiteContent(
        node_locale: { eq: "fr-CA" }
        slug: { eq: "order-confirmation" }
      ) {
        donationLegalCopy: longText {
          childMarkdownRemark {
            html
          }
        }
        delayedShippingNote: shortText
      }

      # ISRAEL - HE
      confirmationAssets_heIL: contentfulSiteContent(
        node_locale: { eq: "he-IL" }
        slug: { eq: "order-confirmation" }
      ) {
        donationLegalCopy: longText {
          childMarkdownRemark {
            html
          }
        }
        delayedShippingNote: shortText
      }

      # MEXICO - ES
      confirmationAssets_esMX: contentfulSiteContent(
        node_locale: { eq: "es-MX" }
        slug: { eq: "order-confirmation" }
      ) {
        donationLegalCopy: longText {
          childMarkdownRemark {
            html
          }
        }
        delayedShippingNote: shortText
      }
    }
  `)

  return getDataByLocale(locale, data, 'confirmationAssets_') || {}
}

export default useConfirmationAssets
