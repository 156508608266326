/** @jsx jsx */
import PropTypes from 'prop-types'
import { Card, jsx } from 'theme-ui'
import LineItems from '~/components/Cart/LineItems'

const OrderConfirmationCart = ({ cart = {} }) => {
  const { lineItems } = cart

  return (
    <Card
      sx={{
        width: '100%',
        padding: '0',
      }}
    >
      <LineItems items={lineItems} orderConfirmed={true} />
    </Card>
  )
}

OrderConfirmationCart.propTypes = {
  cart: PropTypes.shape({
    lineItems: PropTypes.array
  })
}

export default OrderConfirmationCart
