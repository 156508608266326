import React from 'react'
import { Box } from 'theme-ui'

import Text from '~/components/IntlComponents/Text'

const PreOrderNote = ({ note }) => {
  return note ? (
    <Box
      sx={{
        border: '2px solid var(--theme-ui-colors-blazeOrange)',
        borderRadius: '8px',
        color: 'var(--theme-ui-colors-blazeOrange)',
        padding: '.5em',
        margin: '1em 0',
        textAlign: 'center',
      }}
    >
      <Text
        sx={{
          display: 'block',
          width: ['95%', '75%'],
          textAlign: 'center',
          margin: 'auto',
        }}
      >
        {note}
      </Text>
    </Box>
  ) : null
}

export default PreOrderNote
