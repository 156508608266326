/** @jsx jsx */
import { Flex, Container, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import Heading from '~/components/IntlComponents/Heading'
import Text from '~/components/IntlComponents/Text'

const ConfirmationContainer = ({ children, cart = {}, loaded = true }) => {
  const translate = useTranslate()
  return (
    <Container>
      <Flex
        sx={{
          flexDirection: 'column',
          padding: ['24px 0', null, '48px 0'],
        }}
      >
        {loaded && (
          <Heading
            as="h2"
            variant="h2"
            sx={{
              marginBottom: ['24px', null, '64px'],
              textAlign: 'center',
            }}
          >
            {translate('confirmation.order_confirmation')}
          </Heading>
        )}
        {cart.number && (
          <Text
            as="h3"
            sx={{
              textAlign: 'center',
            }}
          >
            {translate('confirmation.order', { number: cart.number })}
          </Text>
        )}
        {children}
      </Flex>
    </Container>
  )
}

export default ConfirmationContainer
