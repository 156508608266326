import { useCallback, useRef } from 'react'

/**
 * As seen here @link https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
 * Refs don't trigger a rerender, putting them in useEffect is not great.
 * Any useEffect that skips rendering won't see the ref until the next render.
 * @param {{callback: (node: import('react').ReactNode) => any, cleanup: (node: import('react').ReactNode) => any}}
 * @returns
 */
function useRefCallback({ callback, cleanup }) {
  const ref = useRef(null)
  const setRef = useCallback(
    node => {
      if (ref.current) {
        // Make sure to cleanup any events/references added to the last instance
        cleanup && cleanup(ref.current)
      }

      if (node) {
        // Check if a node is actually passed. Otherwise node would be null.
        // You can now do what you need to, addEventListeners, measure, etc.
        callback && callback(node)
      }
      ref.current = node
    },
    [callback, cleanup],
  )

  return [setRef]
}

export default useRefCallback
