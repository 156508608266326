/** @jsx jsx */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import copy from 'copy-to-clipboard'
import { Box, Button, Card, Flex, jsx } from 'theme-ui'

import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import Heading from '~/components/IntlComponents/Heading'

import Text from '~/components/IntlComponents/Text'

const OrderConfirmationReferralPrompt = ({ purl = '' }) => {
  const translate = useTranslate()
  const { getValues } = useForm({ defaultValues: { purl } })
  const [copied, setCopied] = useState(false)

  const onCopy = e => {
    e.preventDefault()
    const values = getValues()
    copy(values.purl)
    setCopied(true)
  }

  const referralTitle = 'GIVE SOME MAC, GET SOME BACK'
  const referralDesc =
    'If you get a pal to try GOODLES, we’ll give you a $5 credit and they’ll get 15% off (plus, the chance to get their mind blown by some very delicious mac). It’s a win all around.'

  return (
    <Card
      sx={{
        width: '100%',
        marginBottom: '1.5rem',
        padding: ['32px 18px', '2.5rem'],
      }}
    >
      <Box sx={{ textAlign: ['center', 'left'] }}>
        <Heading as="h3" variant="h3" sx={{ mb: '1.5rem' }}>
          {referralTitle}
        </Heading>
        <Text>{referralDesc}</Text>
      </Box>
      <Flex as="form" sx={{ flexDirection: ['column', 'row'] }}>
        {/* <Box sx={{ width: ['100%', '70%'] }} mb={['0.5rem', '0']}>
          <FormInputWithLabel
            {...register("purl")}
            label={translate('referral.copy_link_input_label')}
            title={translate('referral.copy_link_input_label')}
            aria-label={translate('referral.copy_link_input_label')}
            name="purl"
            readOnly={true}
          />
        </Box> */}
        <Button
          onClick={onCopy}
          disabled={copied}
          sx={{
            width: ['100%', '30%'],
            minWidth: '180px',
            marginTop: '1.5em',
            textTransform: 'capitalize',
          }}
        >
          {copied
            ? translate('referral.copy_link_button_clicked')
            : translate('referral.copy_link_button')}
        </Button>
      </Flex>
    </Card>
  )
}

OrderConfirmationReferralPrompt.propTypes = {
  purl: PropTypes.string.isRequired,
}

export default OrderConfirmationReferralPrompt
