import { Flex } from 'theme-ui'
import React from 'react'

const RowWrapper = ({ children, forwardSx }) => (
  <Flex
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'left',
      my: '.75rem',
      ...forwardSx
    }}
  >
    {children}
  </Flex>
)

export default RowWrapper
