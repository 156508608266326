/** @jsx jsx */
import { Container, Flex, jsx } from 'theme-ui'
import ConfirmationContainer from './ConfirmationContainer'
import Spinner from '~/components/Generic/Spinner'

const ConfirmationLoading = () => {
  return (
    <ConfirmationContainer loaded={false}>
      <Flex
        sx={{
          textAlign: 'center',
          justifyContent: 'center',
          alignContent: 'center',
          width: '100%',
          padding: ['1.25rem', '12rem 1.25rem'],
          marginRight: [null, '1rem'],
          marginBottom: ['1rem', null],
        }}
      >
        <Spinner size="150" sx={{ color: 'text' }} />
      </Flex>
    </ConfirmationContainer>
  )
}

export default ConfirmationLoading
