/** @jsx jsx */
import { Box, Container, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import ConfirmationContainer from './ConfirmationContainer'
import Heading from '~/components/IntlComponents/Heading'

const ConfirmationError = () => {
  const translate = useTranslate()

  return (
    <ConfirmationContainer loaded={false}>
      <Box
        sx={{
          width: '100%',
          backgroundColor: 'white',
          padding: '1rem',
          minHeight: '50vh',
        }}
      >
        <Heading variant="h3" color="errorDark">
          {translate('error.api.default')}
        </Heading>
      </Box>
    </ConfirmationContainer>
  )
}

export default ConfirmationError
