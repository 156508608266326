import React, { useEffect } from 'react'

const YotpoOrderConfirmationScripts = ({ cart, isLoading, orderNumber }) => {
  const { total, currency } = cart

  useEffect(() => {
    if (cart && !isLoading) {
      const yotpoScript1 = document.createElement('script')
      const yotpoScript2 = document.createElement('script')

      yotpoScript1.id = 'yotpo-order-conf-1'
      yotpoScript1.type = 'text/javascript'
      yotpoScript2.id = 'yotpo-order-conf-2'
      yotpoScript2.type = 'text/javascript'

      yotpoScript1.innerHTML = `(function e(){var e=document.createElement("script");e.type="text/javascript",e.async=true,e.src="//staticw2.yotpo.com/${process.env.GATSBY_YOTPO_APP_KEY}/widget.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)})()`
      yotpoScript2.innerHTML = `yotpoTrackConversionData = {orderId: "${orderNumber}", orderAmount: "${total}", orderCurrency: "${currency}"}`

      yotpoScript1.async = false
      yotpoScript2.async = false

      document.head.appendChild(yotpoScript1)
      document.head.appendChild(yotpoScript2)

      return () => {
        document.head.removeChild(yotpoScript1)
        document.head.removeChild(yotpoScript2)
      }
    }
  }, [cart, isLoading])

  return (
    <img
      src={`//api.yotpo.com/conversion_tracking.gif?app_key={{APP_KEY}}&order_id=${orderNumber}&order_amount=${total}&order_currency=${currency}`}
      width="1"
      height="1"
    />
  )
}

export default YotpoOrderConfirmationScripts
